import React, { forwardRef } from 'react';

import { Slot } from '@radix-ui/react-slot';
import cn from 'classnames';

type Props<C extends React.ElementType = 'button'> = React.ComponentPropsWithoutRef<C> & {
  active?: boolean;
  asChild?: boolean;
  /** Makes the button 100% width. */
  block?: boolean;
  children?: React.ReactNode;
  disableBtnClass?: boolean;
  /** Styles the button with disabled state. */
  disabled?: boolean;
  /** Styles the button with focus state. */
  focus?: boolean;
  fullWidth?: boolean;

  /** Invert the button colors. */
  invert?: boolean;
  size?: 'lg' | 'md' | 'sm';
  type?: 'button' | 'reset' | 'submit';
  unstyled?: boolean;
  variant?: 'base' | 'link' | 'primary' | 'refinement' | 'secondary' | 'text';
};

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      active,
      asChild,
      block,
      children,
      className,
      disableBtnClass,
      disabled,
      focus,
      fullWidth,
      invert,
      size = 'md',
      tabIndex,
      type = 'button',
      unstyled,
      variant = 'base',
      ...props
    },
    ref,
  ) => {
    const classes = cn(
      className,
      !unstyled && {
        [`btn-${size}`]: size !== 'md',
        [`btn-${variant}`]: variant !== 'base',
        active: !!active,
        btn: !disableBtnClass && variant !== 'text',
        'btn-block': block,
        'btn-full-width': fullWidth,
        'btn-invert': invert,
        disabled: !!disabled,
        focus: !!focus,
        'has-children': React.Children?.count(children) > 1,
      },
    );

    const Component = asChild ? Slot : 'button';

    return (
      <Component
        {...props}
        className={classes}
        disabled={disabled}
        ref={ref}
        tabIndex={disabled ? -1 : tabIndex}
        type={type}
      >
        {children}
      </Component>
    );
  },
);

Button.displayName = 'Button';

export default Button;
